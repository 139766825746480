// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-index-js": () => import("./../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-cookie-notice-js": () => import("./../src/pages/legal/cookie-notice.js" /* webpackChunkName: "component---src-pages-legal-cookie-notice-js" */),
  "component---src-pages-legal-cookies-preferences-js": () => import("./../src/pages/legal/cookies-preferences.js" /* webpackChunkName: "component---src-pages-legal-cookies-preferences-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */)
}

